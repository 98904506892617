import React from "react"

import Image from "../components/image"
import SEO from "../components/seo"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import Fade from 'react-reveal/Fade'

const AboutPage = () => (
  <>
    <SEO title="A propos d'Uxo" />
    <div
    className="bg-rose min-h-screen flex">
<Fade bottom cascade>
      <article className="text-jaune font-bold lg:w-2/3 text-2xl lg:pl-1/3 py-20 self-center mx-6">
      <p className="text-3xl break-normal leading-snug mb-4">Nous avons créé UXO pour travailler dans une entreprise où s’épanouir et où nous pouvons apprendre au quotidien.</p>

<p className="text-3xl break-normal leading-snug mb-4">Nous apprécions mener des projets variés, qui ont du sens pour nous et dans notre société, tout en s’inscrivant dans celle de demain.</p>

<p className="text-3xl break-normal leading-snug mb-4">Nous avons moins de trente ans et avons grandi dans un monde en mouvement. Ça ne veut pas dire qu’on le comprend mieux mais, au moins, il nous aura appris à nous adapter.</p>

<p className="text-3xl break-normal leading-snug mb-4">Trébucher, réessayer, progresser, sans jamais s’arrêter, sont autant de valeurs qui nous définissent et que nous appliquons chaque jour à UXO.</p>
     </article></Fade> 
    </div> 
  </>
)

export default AboutPage
